<template>
    
    <v-container>
        <v-row>
            <v-col sm="4" cols="12" style="padding:12px 0;">
                <h3 style=" padding: 12px; font-family: 'Krasar'!important;font-size: 19px;line-height: normal;">{{$t('pos_retail_description')}}</h3> 
                <v-col sm="" cols="12" class="">
                    <v-card
                        disabled
                        class="mx-auto grey-custom"
                        max-width="465"
                        outlined
                    >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-row>
                                    <v-col sm="4" cols="4" class="pk-1">
                                        <img
                                            class="img-1"
                                            src="@/assets/images/coo_loyalty.png"
                                            width="80%"
                                        />
                                    </v-col>
                                    <v-col sm="8" cols="8" class="pk-1">
                                            <v-list-item-title class=" mb-1  headline-left">{{$t('loyalty')}}</v-list-item-title>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col> 
                <v-col sm="" cols="12" class="">
                    <v-card
                        class="mx-auto grey-custom"
                        max-width="465"
                        outlined
                    
                    >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-row>
                                    <v-col sm="4" cols="4" class="pk-1">
                                        <img
                                            class="img-1"
                                            src="@/assets/images/coo_session.png"
                                            width="80%"
                                        />
                                    </v-col>
                                    <v-col sm="8" cols="8" class="pk-1">
                                            <v-list-item-title class=" mb-1  headline-left">{{$t('session_management')}}</v-list-item-title>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col sm="" cols="12" class="">
                    <v-card
                        class="mx-auto grey-custom"
                        max-width="465"
                        outlined
                        :to="lang+'/setting'"
                    >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-row>
                                    <v-col sm="4" cols="4" class="pk-1">
                                        <img
                                            class="img-1"
                                            src="@/assets/images/coo_setting.png"
                                            width="80%"
                                        />
                                    </v-col>
                                    <v-col sm="8" cols="8" class="pk-1">
                                        <v-list-item-title class=" mb-1  headline-left">{{$t('setting')}}</v-list-item-title>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col> 
                <v-col sm="" cols="12" class="pk-2">
                    <p class="mb-0 font_13 mt-3 pl-2">{{$t('banhji_footer')}}</p>
                    <v-row>
                        <v-col sm="6" cols="12" class="pt-0">
                            <img
                            class="img-1"
                            src="@/assets/images/made_in_cambodia.png"
                            height="auto"
                            width="100%"
                        />
                        </v-col>
                        <v-col sm="6" cols="12" class="pa-0">
                            <p class="line_14 font_10 mb-3 pl-2">{{$t('banhji_term_footer')}}</p>
                        </v-col>
                    </v-row>
                    
                </v-col>
            </v-col>
            <v-col sm="8" cols="12" class="">
                <v-row>
                    <v-col sm="6" cols="12" class="">
                        <v-card
                            class="mx-auto"
                            max-width="465"
                            outlined
                            :to="lang+'/sale'"
                        >
                            <v-list-item three-line>
                                <v-list-item-content class="pk-3">
                                    <v-list-item-title class="headline mb-1 primary--text">
                                        {{$t('sale')}}
                                    </v-list-item-title>
                                    <div class="overline mb-4 business">
                                    {{$t('point_of_sale')}}
                                    </div>
                                    <v-row>
                                        <v-col sm="5" cols="5" class="">
                                            <img
                                                class="img-1"
                                                src="@/assets/images/coo_pos.png"
                                                width="80%"
                                            />
                                        </v-col>
                                        <v-col sm="7" cols="7" class="pl-0">
                                            <v-list-item-subtitle>Rural Saving & Credit Management for ACs' Credit Business</v-list-item-subtitle>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col> 
                    <v-col sm="6" cols="12" class="">
                            <v-card
                                class="mx-auto"
                                max-width="465"
                                outlined
                                :to="lang+'/invoice_sale'"
                            >
                                <v-list-item three-line>
                                    <v-list-item-content class="pk-3">
                                        <v-list-item-title class="headline mb-1 primary--text">
                                            {{$t('invoice_sale_reports')}}
                                        </v-list-item-title>
                                        <div class="overline mb-4 business">
                                        {{$t('reports')}}
                                        </div>
                                        <v-row>
                                            <v-col sm="5" cols="5" class="">
                                                <img
                                                    class="img-1"
                                                    src="@/assets/images/coo_invoice.png"
                                                    width="80%"
                                                />
                                            </v-col>
                                            <v-col sm="7" cols="7" class="pl-0">
                                                <v-list-item-subtitle>Business and financial management for input supply</v-list-item-subtitle>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                    </v-col> 
                    <v-col sm="6" cols="12" class="">
                        <v-card
                            class="mx-auto"
                            max-width="465"
                            outlined
                            :to="lang+'/orders'"
                        >
                            <v-list-item three-line>
                                <v-list-item-content class="pk-3">
                                    <v-list-item-title class="headline mb-1 primary--text">
                                        {{$t('orders')}}
                                        </v-list-item-title>
                                        <div class="overline mb-4 business">
                                            {{$t('offline_online')}}
                                        </div>
                                        <v-row>
                                            <v-col sm="5" cols="5" class="">
                                                <img
                                                    class="img-1"
                                                    src="@/assets/images/coo_order.png"
                                                    width="80%"
                                                />
                                            </v-col>
                                            <v-col sm="7" cols="7" class="pl-0">
                                                <v-list-item-subtitle>Processing marketing, and financial management</v-list-item-subtitle>
                                            </v-col>
                                        </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col> 
                    <v-col sm="6" cols="12" class="">
                        <v-card
                            class="mx-auto"
                            max-width="465"
                            outlined
                            :to="lang+'/delivery'"
                        >
                            <v-list-item three-line>
                                <v-list-item-content class="pk-3">
                                        <v-list-item-title class="headline mb-1 primary--text">
                                        {{$t('delivery')}}
                                        </v-list-item-title>
                                        <div class="overline mb-4 business">
                                        {{$t('reports')}}
                                        </div>
                                        <v-row>
                                            <v-col sm="5" cols="5" class="">
                                                <img
                                                    class="img-1"
                                                    src="@/assets/images/coo_delivery.png"
                                                    width="80%"
                                                />
                                            </v-col>
                                            <v-col sm="7" cols="7" class="pl-0">
                                                <v-list-item-subtitle>Shared-Service operation,revenue & cost management</v-list-item-subtitle>
                                            </v-col>
                                        </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>                             
    </v-container>
</template>

<script>
    import {i18n} from "@/i18n";
    // import {  data } from '@/observable/store'
    export default {
        data: ()=> ({
        }),
    components: {
    },
    computed:{
        lang() {
            return "/" + i18n.locale;
        },
    },
    methods:{
    }
        
    }
</script>
<style scoped>
body {
    font-family: "Niradei-Regular";
}
.v-application .headline {
    font-size: 2rem !important;
    font-weight: 400;
    line-height: normal;
    letter-spacing: normal !important;
    font-family: 'Niradei-Black'!important;
    margin-bottom: 0 !IMPORTANT;
    text-transform: uppercase;
}
.icon-left{
    margin: 10px 10px 10px 0px !important;
}
.v-application .headline-left{
    font-family: 'Niradei-Heavy' !important;
    font-size: 1.5rem !important;
    white-space: inherit !important;
}
.v-list-item__title, .v-list-item__subtitle {
    white-space: initial !important;
}
.business {
    font-size: 1.2rem !important;
    font-weight: 500;
    letter-spacing: normal !important;
    line-height: 2rem;
    text-transform: uppercase;
    font-family: 'Noto Sans Khmer', sans-serif !important;
}
.v-application .mb-4 {
    margin-bottom: 5px !important;
}
.v-list-item__subtitle {
    overflow: initial !important;
    font-size: 1.1rem !important;
    -webkit-line-clamp: 5;
    font-family: 'Noto Sans Khmer', sans-serif;
    padding-top: 10px;
}
.v-application p {
    margin-bottom: 5px;
}
.pk-1{
    padding: 0px 8px;
    justify-content: center;
    display: flex;
}
.pk-2{
    font-size: 14px;
    padding: 0 12px;
}
.pk-2 span{
    font-size: 12px;
}
.pk-3{
    padding: 10px 0 !important;
}
.v-sheet.v-card{
    border:none;
}
.grey-custom{
    background-color: #e4e6e7;
}
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
    line-height: normal !important;
}
@media (min-width: 1904px){
.container {
    max-width: 1400px;
    }
}
@media (max-width: 576px) {
}
</style>
